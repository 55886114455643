<template> 

  <div>
      <h1> Hexometer web uptime dashboard</h1>
    <div class="owl">
       <carousel
   id= 'move'
    :items= 3
    :nav= false
    :rewind= true
    :autoplayTimeout= 5000
    :dots= false
    :autoplaySpeed= 1300
    :center= true     
    :freeDrag= true
    :mouseDrag= true    
    :autoplay=true >
    <iframe title="Aphen discourse forum" srcDoc='<div  class="hexometer-status-widget"
      data-time_period="30"  data-token="55db76edb484b8774a6f6823b7e23d5102b16310bac0704a61ebd86a6ff1a819"
      data-uptime_resource_id="10760">  Hexometer Status</div>
      <script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>  
    ' />

    <iframe id='one' title= "Carekojo" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="a5519f66a59e0f7e0dfcfbffe9618f407319c7b02507132cc84d9e95e7e11613"  data-uptime_resource_id="10936">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />
     <iframe id='two' title="FMOH dashboards" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="ca9803e27f98a8ecd65a5e4c26c69fb5f42de01e9ec9548669eba7118d5edffc"  data-uptime_resource_id="10934">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe id='three' title= "Datakojo" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="7c35d76abc1fd30b388013fbcfae9b7daf56849092787d65f825c91be89ef3f1"  data-uptime_resource_id="10761">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>' 
    />
     <iframe id='four' title="DHIS2 Training" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="44e2095afa0b54e4642b6ee0c9c439f1ca3c98985ef1b11d02b4687ff875d9e0"  data-uptime_resource_id="10930">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe id='five' title= "DHIStance" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="d51214afd07632a7798567cd3031099fde6f3bba939aaf0873487776969c73de"  data-uptime_resource_id="10925">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />
     <iframe id='six' title="DHI: Digital Health Institute" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="0ef489de7218c7724ccfc280b71ab73e1e84e50568d9663ce4d7f816dec15567"  data-uptime_resource_id="10932">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe id='seven' title= "FMOH Digital library  " srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="262a864d069c22ae7fdc62bd521706128edb10f25dab1db21b788c8264535f04"  data-uptime_resource_id="10926">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>' 
    />
     <iframe id='eight' title="EHealth4everyone" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="133cfa8442648950ca2df2a29d9166d0ed51f4c5aad04ac04564d2f71a6e9a25"  data-uptime_resource_id="10927">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe id='nine' title= "FMOH-DHPRS home  " srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="630e827d50e3a987906b360b7c6f62385d1a87a13055969f65597579e2c44c9c"  data-uptime_resource_id="10933">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>' 
    />
     <iframe id= 'ten' title="Healththink" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="3ae69329a9b3b4c7db2a48ce27b078fbabe4619f4252b8e94fcbe8829bfbb55f"  data-uptime_resource_id="10935">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe id='eleven' title= "FMOH-ISS" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="a98a071b5aed351f30846e86242baba2a1ea030e75ba1b8c5136e97bc395ac72"  data-uptime_resource_id="10359">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />
     <iframe id='twelve' title="Digital-ISS" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="34daea38afe9c605e47eb7c653977d2c9070cfa279c5e2ea831c5f5a7be55c12"  data-uptime_resource_id="10947">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>
    ' />

    
       </carousel> 

       <carousel
   id= 'move'
    :items= 3
    :nav= false
    :rewind= true
    :autoplayTimeout= 5000
    :autoplaySpeed= 1000
    :center= true     
    :freeDrag= true
    :mouseDrag= true    
    :autoplay= true>

    <iframe title= "HPPDM site" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="c1fa72b0164e5058df93063381207d658fb6969fd91f7772ec593a648b1b30f3"  data-uptime_resource_id="10759">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />
     <iframe title="MR-Dhistance" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="177dc949a7844381cec395ab86468acb78171dd70f595ccd23021daec19b2a9f"  data-uptime_resource_id="10931">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>
    ' />

    <iframe title= "WHOMPHD" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="e3b8ea761b11161eefd6e6f2faeb44132a5022301b5afcf9c85d798a0dd37746"  data-uptime_resource_id="10582">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />
     <iframe title="MSDAT Africa" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="2a8d939f46d2d71dfac4b3c2930997282be4707bd34fae90225b030841cad8e4"  data-uptime_resource_id="10948">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe title= "MSDAT Dashboard" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="cfaff878b3b36c5ac23f739b502af98f3d6240476ca3e443bfabd7b55c818586"  data-uptime_resource_id="10361">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>' 
    />
     <iframe title="NHFS MSDAT" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="4362ef92ceca7a57e108bb96e6aeeafbbe39c1a7fbb7d5f4f691d1a935254de8"  data-uptime_resource_id="10363">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe title= "NMDR Nigeria" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="879df581ba9a733ea5e6c9c527bea7919679d65f26f81dcec981573e0f1af91e"  data-uptime_resource_id="10360">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>' 
    />
     <iframe title="OpenMRS Ondoganci" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="c36039e03dba5b1433e56daf4aee7fe419b0d2886dc74d438d864d6e390537e3"  data-uptime_resource_id="11020">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe title= "SL-LMIS Dump" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="db5a418b5b34cfbefaecb57ae57ba3613215a913d3176ff012047b9ef5f458c4"  data-uptime_resource_id="10989">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />
     <iframe title="Personality Tests" srcDoc=' <div  class="hexometer-status-widget"  data-time_period="30"  data-token="5c76276cf048f8e1206f62bd1263c61b75d9c25864e8ebc90d8e39a447340dc5"  data-uptime_resource_id="10928">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> 
    ' />

    <iframe title= "e4e WIki" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="67a031c44bf2ccacf1cc8c44eed32019d5cef934f93b1e59e8c44d5bab20e208"  data-uptime_resource_id="11741">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script> ' 
    />

    <iframe title= "Smartacare" srcDoc='<div  class="hexometer-status-widget"  data-time_period="30"  data-token="9f37410804a3dd7e0de1e477be9bef51073f1e7adec5f1de6ef159cc6f46cd53"  data-uptime_resource_id="10929">  Hexometer Status</div><script>  (function lhswms() {    var lhswms_s_t = document.createElement("script");    lhswms_s_t.src = "https://api.hexometer.com/v2/app/hexometer-status-widget/main-script";    document.body.append(lhswms_s_t);  })();</script>' />
      
       </carousel>
    </div>
 </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
  name: 'HelloWorld',
  components: { carousel },
  props: {
    msg: String
  }, 
}
</script>

<style>

html{
  background-color :  #f8f8fa
}
body {
  background-color: red;
  height: 100%;
  place-items: center;
}
div.hexometer-status-widget {
  width: 800px;
  height: 100px;
}
div h1 {
  background-color: #007d53;  
  color: white;
}
div.owl {
  position:relative; 
  top: 20px;
}
iframe {
  background-color: #f8f8fa;
  border-top: none;
  width: 170%;
  height: 210px;
}

</style>
